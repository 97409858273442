<template>
    <div class="pointGoods_detail">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" label-width="80px" size="small">
                <el-form-item label="商品名称">
                    <el-input readonly v-model="form.name" />
                </el-form-item>
                <el-form-item label="商品描述">
                    <el-input readonly v-model="form.description" />
                </el-form-item>
                <el-form-item label="商品类型">
                    <el-input readonly v-model="form.name" />
                </el-form-item>
                <el-form-item label="折扣">
                    <el-input readonly v-model="form.discount" />
                </el-form-item>
                <el-form-item label="可用次数">
                    <el-input readonly v-model="form.canUseTime" />
                </el-form-item>
                <el-form-item label="有效时间">
                    <el-input readonly v-model="form.effectiveTime" />
                </el-form-item>
                <el-form-item label="购买积分">
                    <el-input readonly v-model="form.buyPoint" />
                </el-form-item>
                <el-form-item label="库存数量">
                    <el-input readonly v-model="form.stock" />
                </el-form-item>
                <el-form-item label="企业">
                    <el-input readonly v-model="form.companyName" />
                </el-form-item>
                <el-form-item label="机构组">
                    <el-input readonly v-model="form.deptGroupName" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'PointGoodsDetail',
    props: ['form'],
    data() {
        return {
            url: {
                page: '/member/memberAllDetail/',
            },
            codes: [],
            tableData: [],
            tableDataDetail: [],
        };
    },
    created() {
        this.form.effectiveTime = this.form.effectiveTime.split('T')[0];
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}

.el-form-item {
    width: auto;
}
</style>
